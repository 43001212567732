import { 
  Button,
  Drawer, Table } from 'antd';
import ColorContext from 'context/ColorContext';
import React, { useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { IAuthAction } from 'types/authTypes';
import { mobileCheck } from 'utils';

import { Row } from 'antd/es/grid';
import { TableRowSelection } from 'antd/lib/table';
import { Close } from 'components/svg';
import { connect } from 'react-redux';
import {
  signOut as signOutAction,
  updateUserEmail,
  updateUserProfile,
} from 'store/actions/authActions';

interface IProps {
  onCloseDrawer: () => void;
  visible: boolean;
  uploadCsv: (file: any) => void;
}

interface ICsvRow {
  [key: string]: string
}

const isMobile = mobileCheck();

const UploadCandidatesDrawer: React.FC<IProps> = ({ onCloseDrawer, visible, uploadCsv }) => {
  const [selectedRows, setSelectedRows] = useState<ICsvRow[]>([]);
  const [allRows, setAllRows] = useState<ICsvRow[]>([]);

  const [selectedFile, setSelectedFile] = useState<string | null>(null)

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;
  
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          setSelectedFile(e.target.result as string)
          const csvRows: string[] = parseCSV(e.target.result as string).slice(1);
          const csvHeaders: string[] = parseCSV(e.target.result as string)[0].split(',');
          const csvRowsObjects: ICsvRow[] = csvRows.map((row) => {
            const values = row.split(',');
          
            const rowData: ICsvRow = {};
            csvHeaders.forEach((header, index) => {
              rowData[header] = values[index] || ''; // If values[index] is undefined, default to an empty string
            });
          
            return rowData;
          });
          
          setAllRows(csvRowsObjects);
        }
      };
      reader.readAsText(file[0]);
    }
  };

  const handleFileUploadButtonClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.csv';
    fileInput.addEventListener('change', (event: any) =>
      handleFileUpload(event as React.ChangeEvent<HTMLInputElement>),
    );
    fileInput.click();
  };


  const handleAddClick = () => {
    uploadCsv(selectedRows);
  };

  

  const parseCSV = (content: string): string[] => {
    return content
      .split('\n')
      .map((row) => row.trim())
      .filter(Boolean);
  };


  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const onSelectChange = (newSelectedRowKeys: any[]) => {
    const updatedSelectedRows = newSelectedRowKeys.map((index) => allRows[parseInt(index, 10)]);
    setSelectedRows(updatedSelectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  

  const columns = selectedFile ? parseCSV(selectedFile)[0].split(',').map((header) => {
    return {
      title: `${header}`,
      dataIndex: `${header}`,
    }
  }) : []
   

  return (
    <>
      <div>
        <Drawer
          title='Upload Candidates [.CSV]'
          placement='right'
          closable={false}
          onClose={() => {
            setAllRows([]);
            setSelectedRows([]);
            onCloseDrawer();
          }}
          visible={visible}
          style={{ position: 'absolute', overflowY: 'auto' }}
          bodyStyle={isMobile ? { width: '95%', marginLeft: '3%' } : {}}
          width={isMobile ? '90vw' : '40vw'}
          className='profile-drawer-container'
        >
          <ColorContext.Consumer>
            {({ theme }) => (
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {allRows.length ? (
                  <div
                    style={{
                      
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    {allRows.length ? (
                      <div
                        style={{
                          border: '1px solid #E1E1E1',
                          borderRadius: '3px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          overflow: 'scroll',
                          marginBottom: '16px',
                          marginTop: '24px',
                          marginLeft: '24px',
                          marginRight: '24px',
                        }}
                      >
                        <Table rowSelection={rowSelection} columns={columns} dataSource={allRows} style={{
                color: theme.primaryBtn.color3
              }} pagination={false}/>
                      </div>
                    ) : (
                      <></>
                    )}
                    
                  </div>
                ) : (
                  <></>
                )}

                {allRows.length ? <div
                    style={{display: 'flex',
                    marginBottom: '24px',
                    marginLeft: '24px',
                    marginRight: '24px',}}
                    >
                    {selectedRowKeys.length ? (
                      
                      <Button
                        type='primary'
                        style={{
                          color: theme.primaryBtn.color3,
                          background: theme.primaryBtn.color1,
                        }}
                        className='form-button'
                        block={true}
                        onClick={handleAddClick}
                      >
                        Add({selectedRowKeys.length})
                      </Button>
                    ) : (
                      <></>
                    )}
                    </div>: <></>}

                {!allRows.length ? (
                  <>
                  <div style={{margin: '24px 24px 16px 24px'}}>
                    <Button
                      type='primary'
                      style={{
                        color: theme.primaryBtn.color3,
                        background: theme.primaryBtn.color1,
                        
                      }}
                      className='form-button'
                      block={true}
                      onClick={handleFileUploadButtonClick}
                    >
                      Upload
                    </Button>
                  </div>
                    <a style={{margin: '0 24px 24px 24px'}} href='/assets/sample.csv' download>
                      <Button
                        style={{
                          color: '#222222',
                          background: '#F2F2F2',
                          
                        }}
                        className='form-button'
                        block={true}
                      >
                        Download Sample File
                      </Button>
                    </a>
                  </>
                ) : (
                  <></>
                )}

                {/* <Col span={isMobile ? 24 : 12} className="profile-drawer-user-quota">
                    <UserQuota profile={profile} />
                  </Col> */}
              </Row>
              
            )}
          </ColorContext.Consumer>
          <div
            className='drawer-close-btn'
            onClick={() => {
              setAllRows([]);
              setSelectedRows([]);
              onCloseDrawer();
            }}
          >
            <Close />
          </div>
        </Drawer>
      </div>
    </>
  );
};

const mapStateToProps = (state: {
  auth: {
    authError: string;
    status: string;
  };
  // @remove-any
  firebase: { auth: any };
}) => {
  return {
    userId: state.firebase.auth.uid,
    authError: state.auth.authError,
    status: state.auth.status,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, IAuthAction>) => {
  return {
    userProfile: (fName: string, image: string, imgUrl: string, organizationDetail: any) =>
      dispatch(updateUserProfile(fName, image, imgUrl, organizationDetail)),
    userEmail: (email: string) => dispatch(updateUserEmail(email)),
    signOut: () => dispatch(signOutAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(UploadCandidatesDrawer);
