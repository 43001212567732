import { Button, Dropdown, Menu } from 'antd';
import {
  CanvasAudioIcon,
  // CanvasFolderIcon,
  CanvasImageIcon,
  CanvasLiveWebsiteIcon,
  CanvasPDFIcon,
  CanvasTextIcon,
  CanvasVideoIcon,
  CanvasWhiteboardIcon,
  DropdownArrow,
  PlusIconSVG,
} from 'components/svg';
import ColorContext from 'context/ColorContext';
import firebaseApp from 'firebase/app';
import { Zmenu } from 'primitives';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { trackAnalytics } from 'utils';
import { v4 as uuid } from 'uuid';
import { ShowNewVideoCanvas } from '../../store/actions/newCanvasActions';
import { createProject as createProjectAction } from '../../store/actions/projectActions';
import { sendMessageToSlack } from '../../store/actions/slackIntegrationAction';
import { mobileCheck, showFullFeature } from '../../utils';
import DropDownCustom from '../dropDown';
import AudioCanvasDialog from '../newCanvas/AudioCanvasDialog';
import ImageCanvasDialog from '../newCanvas/ImageCanvasDialog';
import PdfCanvasDialog from '../newCanvas/PdfCanvasDialog';
import { CanvasCreateIcon } from './assets/Svgs';
import CreateWebsiteModal from './CreateWebsiteModal';
import UploadCandidatesDrawer from './drawers/UploadCandidatesDrawer';

const isMobile = mobileCheck();

const NewSelectDropdown = (props: any) => {
  const isShowFullFeature = showFullFeature();
  const [isWebsiteModal, setIsWebsiteModal] = useState(false);
  const [isImageModal, setIsImageModal] = useState(false);
  const [isPdfModal, setIsPdfModal] = useState(false);
  const [isAudioModal, setIsAudioModal] = useState(false);
  const [hover, setHover] = useState(false);
  const [uploadDrawerVisible, setUploadDrawerVisisble] = useState(false);
  const {
    createProject,
    firebaseAuthToken,
    selectedWorkflowId,
    selectedMasterProjectId,
    profile,
    selectedMasterProject,
    selectedOrganizationData,
  } = props;

  const handleOpenUploadDrawer = () => {
    setUploadDrawerVisisble(true);
  };

  const handleCloseUploadDrawer = () => {
    setUploadDrawerVisisble(false);
  };

  const handleMouseEnter = () => {
    setHover(true);
  };
  const handleMouseLeave = () => {
    setHover(false);
  };

  const { email = '' } = profile;
  useEffect(() => {
    const asyncUseEffect = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const type = urlParams.get('type') || '';
      let userFirebaseAuthToken = firebaseAuthToken;
      const fiebaseInstance: any = firebaseApp;
      if (
        !firebaseAuthToken &&
        fiebaseInstance &&
        fiebaseInstance.auth() &&
        fiebaseInstance.auth().currentUser
      ) {
        userFirebaseAuthToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
      }
      if (
        type &&
        selectedMasterProject &&
        selectedMasterProject.workflow &&
        selectedWorkflowId &&
        selectedMasterProjectId
      ) {
        switch (type) {
          case 'video':
            props.ShowNewVideoCanvas(true);
            const pathname = window.location.pathname;
            window.history.pushState({}, document.title, pathname);
            break;
          case 'text':
            createProject(
              {
                title: 'Untitled',
                project_url: '',
                member_emails: [email],
                type: 'text',
                jiraIssueUrl: '',
                trelloIssueUrl: '',
                project_tags: [],
                workflowId: window.localStorage.getItem('selectedWorkflowId') || selectedWorkflowId,
                masterProjectId: selectedMasterProjectId,
                archived: 'no',
              },
              userFirebaseAuthToken,
              selectedMasterProject,
            ).then((insertedId: any) => {
              const slackMessage = `${profile.fullName} created text type task`;
              const notificationId = uuid();
              const projectURL = `${window.location.origin}/text/${insertedId}?notificationId=${notificationId}`;
              userFirebaseAuthToken &&
                sendMessageToSlack(
                  userFirebaseAuthToken,
                  `${slackMessage}`,
                  insertedId,
                  projectURL,
                  profile.initials ? profile.initials : '',
                  notificationId,
                );

              props.history.push(`/text/${insertedId}`);
            });
            break;
          case 'whiteboard':
            createProject(
              {
                member_emails: [email],
                project_tags: [],
                title: 'Whiteboard',
                type: 'whiteboard',
                project_url: 'https://zooc.io/whiteboard/',
                workflowId: window.localStorage.getItem('selectedWorkflowId') || selectedWorkflowId,
                masterProjectId: selectedMasterProjectId,
                archived: 'no',
              },
              userFirebaseAuthToken,
              selectedMasterProject,
            ).then((insertedId: any) => {
              const slackMessage = `${profile.fullName} created whiteboard type task`;
              const notificationId = uuid();
              const projectURL = `${window.location.origin}/whiteboard/${insertedId}?notificationId=${notificationId}`;
              userFirebaseAuthToken &&
                sendMessageToSlack(
                  userFirebaseAuthToken,
                  `${slackMessage}`,
                  insertedId,
                  projectURL,
                  profile.initials ? profile.initials : '',
                  notificationId,
                );

              props.history.push(`/whiteboard/${insertedId}`);
            });
            break;
          case 'website':
            setIsWebsiteModal(true);
            break;
          case 'pdf':
            setIsPdfModal(true);
            break;
          case 'audio':
            setIsAudioModal(true);
            break;
          case 'image':
            setIsImageModal(true);
            break;
        }
      }
    };
    asyncUseEffect();
  }, [selectedMasterProject, selectedWorkflowId, selectedMasterProjectId]);

  const handleMenuClick = async (e: { key: string }, file?: any) => {
    let userFirebaseAuthToken = firebaseAuthToken;
    const fiebaseInstance: any = firebaseApp;
    if (
      !firebaseAuthToken &&
      fiebaseInstance &&
      fiebaseInstance.auth() &&
      fiebaseInstance.auth().currentUser
    ) {
      userFirebaseAuthToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
    }

    const handleCsvUpload = async (data: { [key: string]: string }[]) => {
      if (data.length > 0) {
        const headers = Object.keys(data[0]);
        
        for (let i = 0; i < data.length; i++) {
          const rowData = data[i];
        
          // Check if all required headers are present
          const allHeadersPresent = headers.every((header) => rowData.hasOwnProperty(header));
    
          if (allHeadersPresent && rowData['Name']) { // Check for existence of 'Name' field
            // Formatting user notes
            const formattedUserNotes = headers
              .filter((header) => rowData[header]) // Only include non-empty values
              .map((header) => `<p>${header}: ${rowData[header]}</p>`)
              .join('');
    
            // Create project with the formatted data
            createProject(
              {
                title: rowData['Name'],
                project_url: '',
                member_emails: [email],
                type: 'text',
                jiraIssueUrl: '',
                trelloIssueUrl: '',
                project_tags: [],
                workflowId: window.localStorage.getItem('selectedWorkflowId') || selectedWorkflowId,
                masterProjectId: selectedMasterProjectId,
                archived: 'no',
                userNotes: formattedUserNotes,
              },
              userFirebaseAuthToken,
              selectedMasterProject,
            ).then((insertedId: any) => {
              const slackMessage = `${profile.fullName} created text type task`;
              const notificationId = uuid();
              const projectURL = `${window.location.origin}/text/${insertedId}?notificationId=${notificationId}`;
              userFirebaseAuthToken &&
                sendMessageToSlack(
                  userFirebaseAuthToken,
                  `${slackMessage}`,
                  insertedId,
                  projectURL,
                  profile.initials ? profile.initials : '',
                  notificationId,
                );
    
              // props.history.push(`/text/${insertedId}`);
              handleCloseUploadDrawer()
            });
          } else {
            console.error('Some required fields are missing or the "Name" field is empty in the uploaded data.');
          }
        }
      }
    };
    

    switch (e.key) {
      case '0':
        trackAnalytics('Canvas', 'Create Text canvas clicked');
        createProject(
          {
            title: 'Untitled',
            project_url: '',
            member_emails: [email],
            type: 'text',
            jiraIssueUrl: '',
            trelloIssueUrl: '',
            project_tags: [],
            workflowId: window.localStorage.getItem('selectedWorkflowId') || selectedWorkflowId,
            masterProjectId: selectedMasterProjectId,
            archived: 'no',
          },
          userFirebaseAuthToken,
          selectedMasterProject,
        ).then((insertedId: any) => {
          const slackMessage = `${profile.fullName} created text type task`;
          const notificationId = uuid();
          const projectURL = `${window.location.origin}/text/${insertedId}?notificationId=${notificationId}`;
          userFirebaseAuthToken &&
            sendMessageToSlack(
              userFirebaseAuthToken,
              `${slackMessage}`,
              insertedId,
              projectURL,
              profile.initials ? profile.initials : '',
              notificationId,
            );

          props.history.push(`/text/${insertedId}`);
        });
        break;

      case '1':
        trackAnalytics('Canvas', 'Upload Csv for creating mulitple Text Canvas');
        //  handleCsvUpload()
        handleOpenUploadDrawer();
        break;
      case '2':
        trackAnalytics('Canvas', 'Create Video canvas clicked');
        props.ShowNewVideoCanvas(true);
        break;
      case '3':
        trackAnalytics('Canvas', 'Create Audio canvas clicked');
        setIsAudioModal(true);
        break;
      case '4':
        trackAnalytics('Canvas', 'Create PDF canvas clicked');
        setIsPdfModal(true);
        break;
      case '5':
        trackAnalytics('Canvas', 'Create Image canvas clicked');
        setIsImageModal(true);
        break;
      case '6':
        trackAnalytics('Canvas', 'Create Text canvas clicked');
        createProject(
          {
            title: 'Untitled',
            project_url: '',
            member_emails: [email],
            type: 'text',
            jiraIssueUrl: '',
            trelloIssueUrl: '',
            project_tags: [],
            workflowId: window.localStorage.getItem('selectedWorkflowId') || selectedWorkflowId,
            masterProjectId: selectedMasterProjectId,
            archived: 'no',
          },
          userFirebaseAuthToken,
          selectedMasterProject,
        ).then((insertedId: any) => {
          const slackMessage = `${profile.fullName} created text type task`;
          const notificationId = uuid();
          const projectURL = `${window.location.origin}/text/${insertedId}?notificationId=${notificationId}`;
          userFirebaseAuthToken &&
            sendMessageToSlack(
              userFirebaseAuthToken,
              `${slackMessage}`,
              insertedId,
              projectURL,
              profile.initials ? profile.initials : '',
              notificationId,
            );

          props.history.push(`/text/${insertedId}`);
        });
        break;
      case isMobile ? '-' : '7':
        trackAnalytics('Canvas', 'Create Whiteboard canvas clicked');
        createProject(
          {
            member_emails: [email],
            project_tags: [],
            title: 'Whiteboard',
            type: 'whiteboard',
            project_url: 'https://zooc.io/whiteboard/',
            workflowId: window.localStorage.getItem('selectedWorkflowId') || selectedWorkflowId,
            masterProjectId: selectedMasterProjectId,
            archived: 'no',
          },
          userFirebaseAuthToken,
          selectedMasterProject,
        ).then((insertedId: any) => {
          const slackMessage = `${profile.fullName} created whiteboard type task`;
          const notificationId = uuid();
          const projectURL = `${window.location.origin}/whiteboard/${insertedId}?notificationId=${notificationId}`;
          userFirebaseAuthToken &&
            sendMessageToSlack(
              userFirebaseAuthToken,
              `${slackMessage}`,
              insertedId,
              projectURL,
              profile.initials ? profile.initials : '',
              notificationId,
            );

          props.history.push(`/whiteboard/${insertedId}`);
        });

        break;

      case 'upload':
        handleCsvUpload(file);
        break;

      case isMobile ? '7' : '8':
        trackAnalytics('Canvas', 'Create Website canvas clicked');
        setIsWebsiteModal(true);
        break;
    }
  };

  const [currentItem, setCurrentItem] = useState(-1);
  const defaultColor = '#4F4F4F';
  const mouseHoverColor = '#fff';

  const menuItems = (hideWhiteBoard: boolean) => {
    const menuItemsArr = [
      // {
      //   icon: (color: string) => <CanvasFolderIcon color={color} />,
      //   text: 'Folder',
      //   separator: true,
      // },
      {
        icon: () => <PlusIconSVG />,
        text: 'Candidates',
      },
      {
        icon: () => <PlusIconSVG />,
        text: 'Upload Candidates',
      },
      {
        icon: (color: string) => <CanvasVideoIcon color={color} />,
        text: 'Video',
      },
      {
        icon: (color: string) => <CanvasAudioIcon color={color} />,
        text: 'Audio',
      },
      {
        icon: (color: string) => <CanvasPDFIcon color={color} />,
        text: 'PDF',
      },
      {
        icon: (color: string) => <CanvasImageIcon color={color} />,
        text: 'Image',
      },
      {
        icon: (color: string) => <CanvasTextIcon color={color} />,
        text: 'Text',
      },
      {
        category: true,
        title: 'Beta',
        titleColor: '#EB5757',
      },
      {
        icon: (color: string) => <CanvasWhiteboardIcon color={color} />,
        text: 'Whiteboard',
      },
      {
        icon: (color: string) => <CanvasLiveWebsiteIcon color={color} />,
        text: 'Website',
      },
    ];
    hideWhiteBoard && menuItemsArr.splice(2, 8);
    return menuItemsArr;
  };

  let iconColor = '';

  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization) {
    if (selectedOrganizationData) {
      userQuota = selectedOrganizationData.userQuota;
    }
  } else {
    if (profile) {
      userQuota = profile.userQuota;
    }
  }

  const menu = (
    <div>
      <ColorContext.Consumer>
        {({ theme }) => (
          <Zmenu onClick={handleMenuClick} menuColors={theme}>
            {menuItems(isMobile).map((menuItem: any, index) => {
              if (
                !isShowFullFeature &&
                (menuItem.title === 'Beta' ||
                  menuItem.text === 'Upload Candidates' ||
                  menuItem.text === 'Candidates' ||
                  menuItem.text === 'Whiteboard' ||
                  menuItem.text === 'Website')
              ) {
                return null;
              }
              iconColor = currentItem === index ? mouseHoverColor : defaultColor;
              if (menuItem.category) {
                return (
                  <div className='newSelectCategory'>
                    <div className='newSelectCategoryTitle'>{menuItem.title}</div>
                  </div>
                );
              }
              return (
                <Menu.Item
                  disabled={userQuota && userQuota.canvasesCount === 0}
                  key={index}
                  onMouseEnter={() => setCurrentItem(index)}
                  onMouseLeave={() => setCurrentItem(-1)}
                >
                  <div className='newSelectItemDetail'>
                    <div className='newSelectItemDetailIcon'>
                      {menuItem.icon && menuItem.icon(iconColor)}
                    </div>
                    <div>{menuItem.text}</div>
                  </div>
                </Menu.Item>
              );
            })}
          </Zmenu>
        )}
      </ColorContext.Consumer>
    </div>
  );

  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <div>
          {isMobile ? (
            <DropDownCustom
              buttonComponent={() => <CanvasCreateIcon />}
              position={'top'}
              items={menu}
              classes={'mobileSelectDropDown'}
            />
          ) : (
            <Dropdown placement={isMobile ? 'topCenter' : undefined} overlay={menu}>
              {isMobile ? (
                <div>
                  <CanvasCreateIcon />
                </div>
              ) : (
                <Button
                  className='newSelectDropDown'
                  style={{
                    color: theme.primaryBtn.color3,
                    background: theme.primaryBtn.color1,
                    ...(hover && { background: theme.primaryBtn.color2 }),
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <span style={{ marginRight: '10px' }}>New</span>
                  <DropdownArrow />
                </Button>
              )}
            </Dropdown>
          )}
          <CreateWebsiteModal
            title=''
            visible={isWebsiteModal}
            setVisible={() => setIsWebsiteModal(false)}
            isEdit={false}
            selectedWorkflowId={selectedWorkflowId}
            selectedMasterProjectId={selectedMasterProjectId}
            email={email}
            selectedMasterProject={selectedMasterProject}
          />
          <ImageCanvasDialog
            isImageModal={isImageModal}
            setIsImageModal={setIsImageModal}
            history={props.history}
            selectedWorkflowId={selectedWorkflowId}
            selectedMasterProjectId={selectedMasterProjectId}
            selectedMasterProject={selectedMasterProject}
            selectedOrganizationData={selectedOrganizationData}
          />
          <AudioCanvasDialog
            isAudioModal={isAudioModal}
            setIsAudioModal={setIsAudioModal}
            history={props.history}
            selectedWorkflowId={selectedWorkflowId}
            selectedMasterProjectId={selectedMasterProjectId}
            selectedMasterProject={selectedMasterProject}
            selectedOrganizationData={selectedOrganizationData}
          />
          <PdfCanvasDialog
            isPdfModal={isPdfModal}
            setIsPdfModal={setIsPdfModal}
            history={props.history}
            selectedWorkflowId={selectedWorkflowId}
            selectedMasterProjectId={selectedMasterProjectId}
            selectedMasterProject={selectedMasterProject}
            selectedOrganizationData={selectedOrganizationData}
          />
          <UploadCandidatesDrawer
            onCloseDrawer={handleCloseUploadDrawer}
            visible={uploadDrawerVisible}
            uploadCsv={(file: any) => handleMenuClick({ key: 'upload' }, file)}
          />
        </div>
      )}
    </ColorContext.Consumer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    firebaseAuthToken: state.auth.firebaseAuthToken,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ShowNewVideoCanvas: (modalVisiblity: boolean) => dispatch(ShowNewVideoCanvas(modalVisiblity)),
    createProject: (project: any, firebaseAuthToken: string, selectedMasterProject: any) =>
      dispatch(createProjectAction(project, firebaseAuthToken, selectedMasterProject)),
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewSelectDropdown) as any) as any;
