import {  Modal } from 'antd';
import React, { FC, Fragment } from 'react';
import ColorContext from '../../context/ColorContext';
import { Zbutton } from '../../primitives';
import TextArea from 'antd/lib/input/TextArea';
import { prompt } from 'kanban-board/constants';
// import CallInitiatedModal from './CallInitiatedModal';

interface IProps {
  visible: boolean;
  step : string;
  handleCancel: (x?:boolean) => void;
  handleSubmit: () => void;
  promptDescription: string;
}

const CallModal: FC<IProps> = ({
  visible,
  step,
  handleCancel,
  handleSubmit,
  promptDescription
}) => {
  // const [isLoading, setIsLoading] = useState(false);
  // const [selectedWorkflowId, setSelectedWorkflowId] = useState('');

  


  return (
    <Modal
      visible={visible}
      onOk={() => handleCancel()}
      onCancel={() => handleCancel()}
      footer={null}
    >
      <ColorContext.Consumer>
        {({ theme }) => (
          <div  style={{ color: theme.text.color2 }}>
              <Fragment>
                <h1>Send batch call to ({step})</h1>
                <div style={{margin : '20px 0'}}>
                  <TextArea value={promptDescription || prompt} rows={15}/>
                </div>
              </Fragment>
            
            <div className='add-step-btn-container'>
              <Zbutton style={ {width : '50%',height : '45px'}} secondary btnColors={theme} onClick={() => handleCancel()}>
                Cancel
              </Zbutton>
              <Zbutton
                style={{ marginLeft: 10, width: '50%',height : '45px' }}
                key='submit'
                primary
                btnColors={theme}
                // loading={isLoading}
                onClick={() => handleSubmit()}
              >
                Send Call
              </Zbutton>
            </div>
          </div>
        )}
      </ColorContext.Consumer>
    </Modal>
  );
};

export default CallModal;
