import { Form, Modal, Switch, notification } from 'antd';
import firebase from 'firebase';
import React, { FC, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import ColorContext from '../../context/ColorContext';
import { Zbutton, Zinput } from '../../primitives';
import { statsAction } from '../../store/actions/authActions';
import { Iworkflow } from '../../types/projectTypes';
import ColorPicker from '../shared/ColorPicker';
import TextArea from 'antd/lib/input/TextArea';
import { prompt } from '../../kanban-board/constants';
import { showFullFeature } from '../../utils';


interface IProps {
  project: any;
  selectedWorkflow: Iworkflow;
  visible: boolean;
  handleCancel: () => void;
  isEdit: boolean;
  form: any;
}

const FormItem = Form.Item;
const WorkflowDialog: FC<IProps> = ({
  form: { resetFields, validateFieldsAndScroll, getFieldDecorator },
  project,
  selectedWorkflow,
  handleCancel,
  visible,
  isEdit,
}) => {
  const isShowFullFeature = showFullFeature();
  const [isLoading, setIsLoading] = useState(false);
  const [updateStep, setUpdateStep] = useState('');
  const [updateVoiceId, setuUpdateVoiceId] = useState('0');
  const [updateMaxDuration, setUpdateMaxDuration] = useState('30');
  const [updateReduceLatency, setUpdateReduceLatency] = useState('true');
  const [updateWaitForGreeting, setUpdateWaitForGreeting] = useState('false');
  const [updateColor, setUpdateColor] = useState('');
  const [batchCall, setBatchCall] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState<boolean>(false);
  const [workFlowDescription, setWorkFlowDescription] = useState('');
  const [promptDescription, setPromptDescription] = useState('');
  const [colorState, setColorState] = useState({
    selectedIndex: isEdit ? 14 : 3,
    selectedColor: isEdit ? updateColor : '#219653',
  });
  const [background, setBackground] = useState<string>('#FFFFFF');

  useEffect(() => {
    setUpdateStep(isEdit && selectedWorkflow.step ? selectedWorkflow.step : '');
    setUpdateColor(isEdit && selectedWorkflow.color ? selectedWorkflow.color : '');

    if(isEdit){
      setuUpdateVoiceId(selectedWorkflow.voiceId || '0');
      setUpdateMaxDuration(selectedWorkflow.maxDuration || '30');
      setUpdateReduceLatency(selectedWorkflow.reduceLatency || 'true');
      setUpdateWaitForGreeting(selectedWorkflow.waitForGreeting || 'false');
    }
  }, [isEdit, selectedWorkflow]);

  useEffect(() => {
    isEdit && setBackground(updateColor);
  }, [updateColor]);

  useEffect(() => {
    if (isEdit) {
      console.log(selectedWorkflow, 'selecteddsjalfkjlsad');
      setBatchCall(selectedWorkflow.batchCall ? selectedWorkflow.batchCall : false);
      setWorkFlowDescription(
        selectedWorkflow.workFlowDescription ? selectedWorkflow.workFlowDescription : '',
      );
      setPromptDescription(
        selectedWorkflow.promptDescription ? selectedWorkflow.promptDescription : '',
      );
      setSelectedPrompt(selectedWorkflow.selectedPrompt ? selectedWorkflow.selectedPrompt : false);
    }else{
      reset();
    }
  }, [isEdit, updateColor, selectedWorkflow]);

  const reset = () => {
    setColorState({
      ...colorState,
      selectedIndex: isEdit ? 14 : 3,
      selectedColor: isEdit ? updateColor : '#219653',
    });
    setIsLoading(false);
    setBatchCall(false);
    setWorkFlowDescription('');
    setSelectedPrompt(false);
    resetFields();
    setPromptDescription("");
  };

  const handleSubmit = (e: any) => {
    console.log('clicked');
    e.preventDefault();
    validateFieldsAndScroll(async (err: any, values: any) => {
      const { stepName, 
        voiceId = '0',
        maxDuration = '30',
        reduceLatency = 'true', 
        waitForGreeting = 'false'  
      } = values;
      setIsLoading(true);
      const updatedWorkflow = project.workflow.map((step: Iworkflow) => {
        if (selectedWorkflow.id === step.id) {
          return {
            ...step,
            step: stepName,
            color: updateColor,
            workFlowDescription: workFlowDescription,
            batchCall: batchCall,
            selectedPrompt: selectedPrompt,
            promptDescription: promptDescription,
            voiceId,
            maxDuration,
            reduceLatency, 
            waitForGreeting
          };
        } else {
          return step;
        }
      });
      if (!err || err === null) {
        setIsLoading(true);
        const firestore = firebase.firestore();
        if (!isEdit) {
          const updatedTimeStamp = new Date().getTime();
          return firestore
            .collection('masterProjects')
            .doc(project.id)
            .set(
              {
                updatedTimeStamp,
                workflow: [
                  ...project.workflow,
                  {
                    id: uuid(),
                    step: stepName,
                    color: colorState.selectedColor,
                    workFlowDescription: workFlowDescription,
                    batchCall: batchCall,
                    selectedPrompt: selectedPrompt,
                    total: 0,
                    promptDescription: promptDescription,
                    voiceId,
                    maxDuration,
                    reduceLatency, 
                    waitForGreeting
                  },
                ],
              },
              { merge: true },
            )
            .then(() => {
              statsAction('CREATE_WORKFLOW_STEP', firestore);
              reset();
              handleCancel();
              notification.success({
                message: 'Workflow Added Successfully',
              });
            })
            .catch(() => {
              console.log('errrrrrrrrrrrrrrrrrrr');
              setIsLoading(false);
              return false;
            });
        } else {
          const updatedTimeStamp = new Date().getTime();
          return firestore
            .collection('masterProjects')
            .doc(project.id)
            .set({
              ...project,
              workflow: updatedWorkflow,
              updatedTimeStamp,
            })
            .then(() => {
              statsAction('UPDATE_WORKFLOW_STEP', firestore);
              reset();
              handleCancel();
              notification.success({
                message: 'Workflow Updated Successfully',
              });
            })
            .catch(() => {
              setIsLoading(false);
              return false;
            });
        }
      }
      setIsLoading(false);
    });
  };

  const handleSelectedPrompt = () => {
    setSelectedPrompt((prev) => !prev);
    setPromptDescription(prompt);
    // console.log(e.target.name,'kdsjflkfdsj')
  };
 
  const newDocument:any = document;
  const params = new URL(newDocument.location).searchParams;
  const fullConfig = params.get('full-config')

  return (
    <div>
      <ColorContext.Consumer>
        {({ theme }) => (
          <Modal
            visible={visible}
            onOk={handleCancel}
            onCancel={() => handleCancel()}
            footer={null}
          >
            <div className='add-step-container' style={{ color: theme.text.color2 }}>
              {/* <h2 style={{ color: theme.text.color2 }}>Add workflow step</h2> */}
              {/* <p>Some contents...</p> */}
              <Form className='white'>
                <h2>Edit Workflow step</h2>
                <div style={{ marginBottom: '10px', position: 'relative' }}>
                  <div
                    className='selected-color'
                    style={{ background: isEdit ? updateColor : colorState.selectedColor }}
                  />
                  <FormItem style={{ marginBottom: 10 }}>
                  {getFieldDecorator('stepName', {
                    initialValue: isEdit && updateStep ? updateStep : '',
                    rules: [
                      {
                        required: true,
                        message: 'Please input step name!',
                        whitespace: true,
                      },
                    ],
                  })(<Zinput placeholder='Enter Step Name' secondary />)}
                  </FormItem>
                </div>
                <div style={{ marginBottom: '16px 0' }}>
                  <ColorPicker
                    background={background}
                    setBackground={setBackground}
                    colorState={colorState}
                    setColorState={setColorState}
                    setUpdateColor={setUpdateColor}
                  />
                </div>
                <div style={{ margin: '16px 0' }}>
                  <label style={{ fontSize: '16px' }}>Workflow Description</label>
                  <TextArea
                    value={workFlowDescription}
                    onChange={(e) => setWorkFlowDescription(e.target.value)}
                    style={{ marginTop: '10px' }}
                    placeholder='Description goes here'
                  />
                </div>

                {/* <Form.Item
                  label='Batch Calls'
                  // style={{ display: 'flex', alignItems: 'center'}}
                  // {...{ labelCol: { span: 4 }, wrapperCol: { span: 14 } }}
                > */}
                { isShowFullFeature && <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '16px 0',
                  }}
                >
                  <label style={{ fontSize: '16px' }}>Batch Calls</label>
                  <Switch checked={batchCall} onChange={(value) => setBatchCall(value)} />
                </div> }
                {batchCall && fullConfig && (
                <>
                  <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '16px 0',
                  }}
                  >
                  <label style={{ fontSize: '16px' }}>Voice Id</label>
                  <FormItem style={{ marginBottom: 10 }}>
                  {getFieldDecorator('voiceId', {
                      initialValue: updateVoiceId.toString(),
                      rules: [
                        {
                          required: true,
                          message: 'Please input voice id!',
                          whitespace: true,
                        },
                      ],
                    })(<Zinput placeholder='1' secondary />)}
                    </FormItem>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '16px 0',
                      }}
                      >
                      <label style={{ fontSize: '16px' }}>Max duration</label>
                      <FormItem style={{ marginBottom: 10 }}>
                      {getFieldDecorator('maxDuration', {
                        initialValue: updateMaxDuration.toString(),
                        rules: [
                          {
                            required: true,
                            message: 'Please input max duration!',
                            whitespace: true,
                          },
                        ],
                      })(<Zinput placeholder='10' secondary />)}
                      </FormItem>
                    </div>

                    <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      margin: '16px 0',
                    }}
                    >
                    <label style={{ fontSize: '16px' }}>Reduce Latency</label>
                    <FormItem style={{ marginBottom: 10 }}>
                    {getFieldDecorator('reduceLatency', {
                      initialValue: updateReduceLatency.toString(),
                      rules: [
                        {
                          required: true,
                          message: 'Please input reduce latency!',
                          whitespace: true,
                        },
                      ],
                    })(<Zinput placeholder='true' secondary />)}
                    </FormItem>
                    </div>

                    <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      margin: '16px 0',
                    }}
                    >
                    <label style={{ fontSize: '16px' }}>Wait for Greeting</label>
                    <FormItem style={{ marginBottom: 10 }}>
                    {getFieldDecorator('waitForGreeting', {
                      initialValue: updateWaitForGreeting.toString(),
                      rules: [
                        {
                          required: true,
                          message: 'Please input wait for greeting!',
                          whitespace: true,
                        },
                      ],
                    })(<Zinput placeholder='false' secondary />)}
                    </FormItem>
                    </div>
                      </>
                )}
                {/* </Form.Item> */}
                {batchCall ? (
                  <>
                    <div>
                      <div style={{ marginBottom: '10px' }}>
                        Prompt (
                        <a href='#' style={{ color: '#306AE1' }}>
                          prompting guide
                        </a>
                        )
                      </div>
                      <p>Try one of these pre-written options to start.</p>
                      <div style={{ display: 'flex', gap: '12px', margin: '10px 0' }}>
                        <Zbutton
                          primary={selectedPrompt}
                          secondary={!selectedPrompt}
                          btnColors={theme}
                          onClick={() => handleSelectedPrompt()}
                          style={{ width: '20%' }}
                        >
                          Recruiter
                        </Zbutton>
                      </div>
                    </div>
                    <Form.Item style={{ margin: '20px 0' }}>
                      <TextArea
                        value={promptDescription}
                        placeholder='Enter Promt here...'
                        rows={10}
                        onChange={(e) => setPromptDescription(e.target.value)}
                      />
                    </Form.Item>
                  </>
                ) : (
                  ''
                )}

                <div className='add-step-btn-container'>
                  <Zbutton
                    secondary
                    btnColors={theme}
                    onClick={() => handleCancel()}
                    style={{ marginRight: 10, width: '50%', height: 40 }}
                  >
                    Cancel
                  </Zbutton>
                  <Zbutton
                    primary
                    key='submit'
                    btnColors={theme}
                    loading={isLoading}
                    onClick={handleSubmit}
                    style={{ width: '50%', height: 40 }}
                  >
                    {isEdit ? 'Save Changes' : 'Save'}
                  </Zbutton>
                </div>
              </Form>
            </div>
          </Modal>
        )}
      </ColorContext.Consumer>
    </div>
  );
};

export default Form.create<IProps>()(WorkflowDialog);
